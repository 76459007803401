import { Controller } from "@hotwired/stimulus"
import { i18n } from "../config/i18n";
import "../utils/validations";
import confetti from "canvas-confetti";

// Connects to data-controller="file-uploader"
export default class extends Controller {
  static outlets = ["location"];
  static values = { counter: Number }
  static targets = [
    "fileUploader",
    "overlay",
    "imageGallery",
    "emptyImagePlaceholder",
    "imageTemplate",
    "locationImagesInput",
    "locationNameInput",
    "locationNameLoader",
    "locationLatitude",
    "locationLongitude",
    "fileUploaderButton",
    "fileUploaderLoader",
    "fileInput",
    "formDialog",
    "formErrors",
    "form",
    "successForm",
    "materialsGrid"
  ]

  connect() {}

  successFormTargetConnected() {
    this.thankYou();
  }

  showCamera() {
    this.fileInputTarget.click();
  }

  // check if file is of type image and prepend the initialized
  // template to the target element
  attachFiles(files, isDrop) {
    let tempFiles = new DataTransfer();
    if (isDrop) {
      for (let i = 0; i < this.locationImagesInputTarget.files.length; i++) {
        tempFiles.items.add(this.locationImagesInputTarget.files.item(i));
      }
    } else {
      this.imageGalleryTarget.querySelectorAll("li").forEach((e) => {
        if (e.id !== "empty") e.remove();
      });
      this.emptyImagePlaceholderTarget.classList.remove("hidden");
    }
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      const objectURL = URL.createObjectURL(file);
      const isImage = file.type.match("image.*");
      if (!isImage) return;
      const clone = this.imageTemplateTarget.content.cloneNode(true)

      clone.querySelector("h3").textContent = file.name;
      clone.querySelector("li").id = file.name;
      clone.querySelector(".size").textContent =
        file.size > 1024
          ? file.size > 1048576
            ? Math.round(file.size / 1048576) + "mb"
            : Math.round(file.size / 1024) + "kb"
          : file.size + "b";

      isImage &&
      Object.assign(clone.querySelector("img"), {
        src: objectURL,
        alt: file.name
      });

      this.emptyImagePlaceholderTarget.classList.add("hidden");
      this.imageGalleryTarget.prepend(clone);

      tempFiles.items.add(file);

      if (isDrop) {
        this.overlayTarget.classList.remove("draggedover");
        this.counterValue = 0;
      }
    }
    this.locationImagesInputTarget.files = tempFiles.files;
  }

  // use to check if a file is being dragged
  hasFiles({ dataTransfer: { types = [] } }) { return types.indexOf("Files") > -1 }

  dragOverHandler(e) {
    if (this.hasFiles(e)) {
      e.preventDefault();
    }
  }

  dragLeaveHandler(_e) {
    1 > --this.counterValue && this.overlayTarget.classList.remove("draggedover");
  }

  dragEnterHandler(e) {
    e.preventDefault();
    if (!this.hasFiles(e)) {
      return;
    }
    ++this.counterValue && this.overlayTarget.classList.add("draggedover");
  }

  dropHandler(ev) {
    ev.preventDefault();
    this.attachFiles(this.locationImagesInputTarget.files, true);
  }

  deleteImage(e) {
    if (e.target.classList.contains("danger")) {
      const ou = e.target.dataset.target;
      const dt = new DataTransfer();
      const { files } = this.locationImagesInputTarget;
      document.getElementById(ou).remove();
      this.imageGalleryTarget.children.length === 1 && this.emptyImagePlaceholderTarget.classList.remove("hidden");
      for (let i = 0; i < files.length; i++) {
        if (ou !== files[i].name) {
          dt.items.add(files[i])
        }
      }
      this.locationImagesInputTarget.files = dt.files;
    }
  }

  selectFiles() {
    this.locationImagesInputTarget.click();
  }

  uploadImages() {
    const files = this.hasFileInputTarget ? this.fileInputTarget.files : this.locationImagesInputTarget.files;

    if (this.hasFileUploaderButtonTarget) {
      this.fileUploaderButtonTarget.lastElementChild.style.display = "none";
      this.fileUploaderLoaderTarget.style.display = "flex";
    }

    if (Array.from(files).filter((file) => file.type.match("image.*")).length > 0) {
      const formData = new FormData();
      let i = 0;
      for (const photo of files) {
        formData.append("images[]", photo);
        i++;
      }
      fetch(`/locations/fetch_nearest_address`, {
        method: "POST",
        body: formData,
        headers: {
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then((response) => response.json())
      .then((json) => {
        if (!json.address && !json.latitude && !navigator.geolocation) {
          alert(i18n.t("new_location_error"))
        } else {
          if (json.latitude && json.longitude) {
            this.attachFiles(files, false);
            this.setNewLocationData(json);
          } else {
            navigator.geolocation.getCurrentPosition((position) => {
              const gpsFormData = new FormData();
              const { latitude, longitude } = position.coords

              gpsFormData.append("coordinates[latitude]", latitude.toString());
              gpsFormData.append("coordinates[longitude]", longitude.toString());

              fetch(`/locations/fetch_nearest_address`, {
                method: "POST",
                body: gpsFormData,
                headers: {
                  "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
                }
              }).then((response) => response.json())
              .then((json) => {
                if (json.latitude && json.longitude) {
                  this.attachFiles(files, false);
                  this.setNewLocationData(json);
                } else {
                  this.closeFormDialog();
                  return alert(i18n.t("new_location_error"));
                }
              })
            }, () => {
              this.closeFormDialog();
              return alert(i18n.t("new_location_error"));
            });
          }
        }
      });
    }
  }

  selectText(e) {
    e.target.select();
  }

  setNewLocationData(json) {
    if (!this.hasLocationLatitudeTarget && !this.hasLocationLongitudeTarget) return;

    this.locationOutlet.centerCoordinatesValue = [json.longitude, json.latitude];
    this.locationLatitudeTarget.value = json.latitude;
    this.locationLongitudeTarget.value = json.longitude;

    if (json.address) {
      this.locationNameInputTarget.value = json.address;
    }
    this.locationNameLoaderTarget.style.display = "none";
    this.locationNameInputTarget.hidden = false;

    if (this.hasFileUploaderButtonTarget) {
      this.fileUploaderLoaderTarget.style.display = "none";
      this.fileUploaderButtonTarget.lastElementChild.style.display = "contents";
    }

    if (this.hasFormDialogTarget) {
      this.formDialogTarget.showModal();
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
      this.formDialogTarget.children[0].scrollIntoView(true);
    }
  }

  updateAddress({ detail: { coordinates } }) {
    const { lat, lng } = coordinates;

    this.locationNameInputTarget.classList.add("hidden");
    this.locationNameInputTarget.hidden = true;
    this.locationNameInputTarget.value = "";
    this.locationNameLoaderTarget.style.display = "block";

    const gpsFormData = new FormData();
    gpsFormData.append("coordinates[latitude]", lat.toString());
    gpsFormData.append("coordinates[longitude]", lng.toString());

    fetch(`/locations/fetch_nearest_address`, {
      method: "POST",
      body: gpsFormData,
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
      }
    }).then((response) => response.json())
    .then((json) => {
      this.locationNameInputTarget.value = json.address ?? "Point";
      this.locationNameLoaderTarget.style.display = "none";
      this.locationNameInputTarget.classList.remove("hidden");
      this.locationNameInputTarget.hidden = false;
    })
  }

  closeDialog() {
    if (this.hasFormDialogTarget) {
      this.formDialogTarget.close();
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }

  closeFormDialog(event) {
    if (!this.hasFormDialogTarget) return;
    if (event && event.params && event.params.confirmation && !confirm(i18n.t("confirm_deletion"))) return;

    this.closeDialog();

    this.locationOutlet.centerCoordinatesValue = [];
    this.locationOutlet.newMarker = null;
    this.fileInputTarget.files = new DataTransfer().files;
    this.formTarget.reset();
    this.formErrorsTarget.innerHTML = "";

    this.locationNameLoaderTarget.style.display = "none";
    this.locationNameInputTarget.hidden = false;

    if (this.hasFileUploaderButtonTarget) {
      this.fileUploaderLoaderTarget.style.display = "none";
      this.fileUploaderButtonTarget.lastElementChild.style.display = "contents";
    }
    window.newMap?.remove();
  }

  validateInput(event) {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      window.FormValidator.validateField(event.target);
    }, 600);
  };

  validateMaterialType() {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      if (this.materialsGridTarget.querySelectorAll("input[type=checkbox]:checked").length > 0) {
        this.materialsGridTarget.classList.remove("error", "error-pulsate");
        this.materialsGridTarget.classList.add("valid");
      } else {
        this.materialsGridTarget.classList.remove("valid");
        this.materialsGridTarget.classList.add("error", "error-pulsate");
      }
    }, 300);
  }

  onSubmit() {
    const locationNameValue = this.locationNameInputTarget.value
    document.querySelectorAll("input:invalid").forEach((element) => {
      window.FormValidator.validateField(element);
    });
    if (this.formTarget.checkValidity() && locationNameValue && (this.materialsGridTarget.querySelectorAll("input[type=checkbox]:checked").length > 0)) {
      this.locationNameInputTarget.classList.remove('error');
      this.locationNameInputTarget.classList.add('valid');

      this.materialsGridTarget.classList.remove("error", "error-pulsate");
      this.materialsGridTarget.classList.add("valid");

      Turbo.navigator.submitForm(this.formTarget);
    } else {
      if (!locationNameValue) {
        this.locationNameInputTarget.classList.remove('valid');
        this.locationNameInputTarget.classList.add('error');
      }

      if (this.materialsGridTarget.querySelectorAll("input[type=checkbox]:checked").length === 0) {
        this.materialsGridTarget.classList.remove("valid");
        this.materialsGridTarget.classList.add("error", "error-pulsate");
      }
    }
  }

  thankYou() {
    const duration = 10 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    let interval = setInterval(function() {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(Object.assign({}, defaults, { particleCount, origin: { x: (Math.random() * (0.3 - 0.1) + 0.1), y: Math.random() - 0.2 } }));
      confetti(Object.assign({}, defaults, { particleCount, origin: { x: (Math.random() * (0.9 - 0.7) + 0.7), y: Math.random() - 0.2 } }));
    }, 250);
  }
}
