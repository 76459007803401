import { Controller } from "@hotwired/stimulus"
import mapboxgl from "mapbox-gl";

// Connects to data-controller="location"
export default class extends Controller {
  static values = {
    mapboxAccessToken: String,
    mapboxStyleId: String,
    centerCoordinates: Array,
  }

  static targets = [
    "locationLatitude",
    "locationLongitude"
  ]

  centerCoordinatesValueChanged(current, _) {
    if (!current.length) return;

    if (!mapboxgl.supported()) {
      alert('Your browser does not support our map renderer service!');
    } else {
      mapboxgl.accessToken = this.mapboxAccessTokenValue;
      window.newMap = new mapboxgl.Map({
        container: 'location-map',
        style: `mapbox://styles/sunergos/${this.mapboxStyleIdValue}`,
        center: this.centerCoordinatesValue,
        attributionControl: false,
        zoom: 17,
        maxZoom: 20
      });
      newMap.on('load', () => {
        const location = new mapboxgl.Marker({draggable: true}).setLngLat({
          lng: this.locationLongitudeTarget.value,
          lat: this.locationLatitudeTarget.value,
        }).addTo(newMap)
        location.on("dragend", () => {
          const coordinates = location.getLngLat();
          this.locationLatitudeTarget.value = coordinates.lat;
          this.locationLongitudeTarget.value = coordinates.lng;
          newMap.flyTo({ center: coordinates })
          this.dispatch("moved", { detail: { coordinates } })
        });
      })
    }
  }
}
