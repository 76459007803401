window.FormValidator = {
  validateField(element, errorMessage) {
    if (!element.validity.valid || errorMessage) {
      const message = errorMessage || element.validationMessage;
      const hasError = element.nextSibling?.className?.includes('invalid-feedback');
      if (!hasError) {
        const error = document.createElement('div');
        error.classList.add('error-feedback');
        error.innerText = message;
        element.parentNode.insertBefore(error, element.nextSibling);
      } else if (hasError && message !== element.nextSibling.innerText) {
        element.nextSibling.innerText = element.validationMessage;
      }
      element.classList.remove('valid');
      element.classList.add('error');
    } else {
      element.classList.remove('error');
      element.classList.add('valid');
      if (element.nextSibling?.className?.includes('error-feedback')) {
        element.parentNode.removeChild(element.nextSibling);
      }
    }
  },
};
