import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = [ "slide", "next", "previous" ]

  initialize() {
    this.index = 0
    this.showCurrentSlide()
  }

  slideTargetConnected() {
    this.toggleCarouselVisibility();
  }

  slideTargetDisconnected() {
    this.toggleCarouselVisibility();
  }

  next() {
    this.index = this.index === this.slideTargets.length - 1 ? 0 : this.index + 1
    this.showCurrentSlide()
  }

  previous() {
    this.index = this.index === 0 ? this.slideTargets.length - 1 : this.index - 1
    this.showCurrentSlide()
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      element.hidden = index !== this.index
    });
  }

  toggleCarouselVisibility() {
    this.element.hidden = this.slideTargets.length === 0;
    this.previousTarget.hidden = this.slideTargets.length === 1;
    this.nextTarget.hidden = this.slideTargets.length === 1;
  }
}
